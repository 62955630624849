<template>
    <div class="dialog-box">
        <div class="close" @click="handleClose">
            <i class="iconfont iconguanbi"></i>
        </div>
        <div class="content">
            <div class="sub-item">
                <p class="item_title">
                    <label><i class="iconfont icontixing"></i>系统更新提示！</label>
                </p>
                <p class="item_time">
                    <label>更新时间：-----</label>
                </p>
            </div>
            <div class="txt_box">
                <div class="title">
                    升级内容如下
                </div>
                <div class="txt_content">
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                    <div class="p_box">
                        <div class="num">
                            1
                        </div>
                        <div class="text_inner">
                            具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下具体升级内容如下
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns">
                <el-button class="btn btn2" @click="handleClose">
                    关闭
                </el-button>
                <el-button
                    class="btn btn1"
                    @click="handleClose"
                >
                    已阅
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sys-update',
    data() {
        return {
            msgTime: '',
            msgTxt: '', // 消息文本
        };
    },
    props: {
        extr: Object,
    },
    created() {
        if (this.extr) {
            this.msgTime = this.extr.msgTime;
            this.msgTxt = this.extr.msgTxt;
        }
    },
    methods: {
        handleClose() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
    .dialog-box
        position: relative;
        z-index: 11;
        width 100%;
        height 100%
        .close
            position absolute;
            right 0.1rem;
            top 0.1rem;
            width: 0.25rem;
            height: 0.25rem;
            padding-top 0.01rem;
            display flex;
            justify-content center;
            align-items center;
            cursor pointer;
            .iconguanbi
                font-size 0.14rem;
                color #fff;
        .content
            box-sizing border-box
            width 100%;
            height 100%
            padding 0.2rem 0.4rem;
            background #fff;
            background url("../../../../images/update_bg1.png") no-repeat;
            background-size 100%;
            .title
                img
                    width 2.54rem;
            .sub-item
                margin-top 0.18rem;
                .item_title
                    label
                        font-size .24rem !important
                        i
                            font-size .24rem !important
                p
                    margin 0.1rem 0;
                    display flex
                    align-items center
                    label
                        font-size 0.14rem;
                        color #fff
                        i
                            font-size 0.14rem;
                            margin-right 0.06rem;
                    span
                        color #FA6400
            .txt_box
                padding 0.1rem;
                padding-top 0;
                height 3.85rem;
                font-size 0.14rem;
                line-height: 0.24rem;
                color #5C5C5C;
                overflow-x hidden;
                overflow-y auto;
                background: #fff;
                border-radius: 10px;
                padding: .2rem;
                background: url(../../../../images/update_bg2.png) right bottom no-repeat #fff;
                background-size: 100%;
                .title
                    font-size: .2rem;
                    font-weight: 600;
                    padding-bottom: .1rem;
                    background: url(../../../../images/icon_z.png) left no-repeat #fff;
                    background-size: .35rem;
                    padding-left: .38rem;
                .txt_content
                    .p_box
                        display flex
                        .num
                            width .2rem
                            color #0078FB
                        .text_inner
                            flex 1
            .btns
                height .6rem
                display flex;
                justify-content space-around;
                align-items center;
                position absolute
                bottom 0
                left 50%
                transform translate(-50%)
                .btn
                    display flex
                    justify-content center;
                    align-items center;
                    width: 1.4rem;
                    height: 0.4rem;
                    border-radius: 1px;
                    border: 1px solid #1577D2;
                    color #1577D2;
                    font-size 0.16rem;
                    &.btn1
                        background: #1577D2;
                        border-radius: 1px;
                        color #fff;
</style>
